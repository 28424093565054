import writeXlsxFile, { Columns, SheetData } from 'write-excel-file';

export interface TableSortType {
    column_idx: number,
    sort_direction: SortDirection
};

export enum ForEachFilter {
    AllRows,
    OnlyVisible,
    AllFiltered
}

export enum SortDirection {
    Ascending,
    Descending
}

export enum RenderTypeEnum {
    Display,
    Sort
}

export enum ColumnTypeEnum {
    Number,
    String
}

export interface Column<T> {
    data: string,
    title: string,
    render?: (data: any, row: T, type: RenderTypeEnum, column: Column<T>) => string | number,
    sortType?: ColumnTypeEnum,
    name?: string,
    cssStyle?: string[],
    conditionalCellStyling?: (row: T) => string[],
    canSort?: boolean
};

interface Tablei18n {
    emptyTable?: string
}

export interface ConfigType<T> {
    filter?: (a: T) => boolean
    id: string
    columns?: Column<T>[]
    data?: T[],
    sort?: TableSortType[],
    conditionalRowStyling?: (row: T) => string[],
    cellClicked?: (column: Column<T>, row: T) => void,
    paging?: number[],
    rowsPerPage?: number,
    i18n?: Tablei18n
};

interface VirtualTableRowCellType {
    contents: string,
    cssStyle: string[]
}

interface VirtualTableRowType<T> {
    cells: VirtualTableRowCellType[],
    row: T,
    cssStyle: string[]
}

interface VirtualTableType<T> {
    rowsOnPage: VirtualTableRowType<T>[],
    allFilteredRows: T[],
}

export class ErikTable<Key, Value> {
    setConditionalRowStyling(func: (row: Value) => string[]): void {
        this.#config.conditionalRowStyling = func;
    }

    CurrentPageIndex(): number {
        return this.#pageIndex;
    }

    SetCurrentPageIndex(index: number): void {
        this.#pageIndex = index;
    }

    NumPages(): number {
        return Math.max(1, Math.ceil(this.#currentTable.allFilteredRows.length / this.#config.rowsPerPage));
    }

    createVirtualTable(): VirtualTableType<Value> {
        const table: VirtualTableType<Value> = {
            'rowsOnPage': [],
            'allFilteredRows': []
        };

        const firstRowNumber = Math.max(0, Math.min(this.#pageIndex * this.#config.rowsPerPage, this.#data.length - 1)) + 1;

        const filter = this.#config.filter != null;
        for (let i = 0; i < this.#data.length; ++i) {
            if (filter) {
                if (!this.#config.filter(this.#data[i])) {
                    continue;
                }
            }
            table.allFilteredRows.push(this.#data[i]);
            if (table.allFilteredRows.length >= firstRowNumber && (this.#config.rowsPerPage == -1 || table.rowsOnPage.length < this.#config.rowsPerPage)) {
                var rowStyle: string[] = [];
                if (this.#config.conditionalRowStyling != null) {
                    rowStyle = this.#config.conditionalRowStyling(this.#data[i]);
                }

                const row: VirtualTableRowType<Value> = {
                    'cells': [],
                    'row': this.#data[i],
                    'cssStyle': rowStyle
                };

                for (const column of this.#config.columns) {
                    var cellStyle: string[] = [];

                    if (column.conditionalCellStyling != null) {
                        cellStyle = column.conditionalCellStyling(row.row);
                    }

                    if (column.cssStyle != null) {
                        for (const style of column.cssStyle) {
                            cellStyle.push(style);
                        }
                    }

                    const cell: VirtualTableRowCellType = {
                        'contents': '',
                        'cssStyle': cellStyle
                    };

                    let value: string | number;
                    if (column.render != null) {
                        value = column.render(this.#data[i][column.data], this.#data[i], RenderTypeEnum.Display, column);
                    } else {
                        value = this.#data[i][column.data];
                    }

                    if (value !== null) {
                        cell.contents = value.toString();
                    }

                    row.cells.push(cell);
                }

                table.rowsOnPage.push(row);
            }
        }


        return table;
    }

    #_createClassName(set: string[]): string {
        let out = '';
        for (const item of set) {
            out += item + ' ';
        }
        return out.trimEnd();
    }

    constructor(container: string | HTMLDivElement, config: ConfigType<Value>) {
        let containerElement: HTMLDivElement;
        if (typeof (container) == 'string') {
            containerElement = document.getElementById(container) as HTMLDivElement;
        } else {
            containerElement = container;
        }

        this.#config = config;

        if (this.#config.id == null) {
            console.error('"id" must be set');
            return;
        }


        if (this.#config.paging == null) {
            this.#config.rowsPerPage = -1;
        } else {
            if (this.#config.rowsPerPage == null) {
                this.#config.rowsPerPage = this.#config.paging[0];
            }
        }

        // Create the table element
        const tableElement = document.createElement('table');
        tableElement.classList.add("erik-table");
        containerElement.appendChild(tableElement);

        const that = this;

        const thead = document.createElement('thead');
        let i = 0;
        if (this.#config.columns != null) {
            const tr = document.createElement('tr');
            for (const column of this.#config.columns) {
                const th = document.createElement('th');
                th.innerText = column.title;

                let style: string[] = [];
                if (column.cssStyle != null) {
                    for (const value of column.cssStyle) {
                        style.push(value);
                    }
                }

                if (column.canSort == null || column.canSort) {
                    style.push('sortable');
                    style.push('up-arrow');
                    style.push('down-arrow');
                }

                th.className = this.#_createClassName(style);

                th.dataset.index = (i++).toString();
                th.onclick = function (event) {
                    that.sort(Number.parseInt((this as HTMLTableCellElement).dataset.index), event.shiftKey);
                    event.stopPropagation();
                }

                tr.appendChild(th);
            }
            thead.appendChild(tr);
        }

        tableElement.appendChild(thead);
        tableElement.appendChild(this.#tbody);

        this.#paginationContainer.classList.add('pagination-container');
        containerElement.append(this.#paginationContainer);

        if (this.#config.sort != null) {
            this.#sort = this.#config.sort;
        }

        this.#_MarkSortDirection();

        if (this.#config.data != null) {
            for (const row of this.#config.data) {
                this.#data.push(row);
            }

            for (const row of this.#data) {
                this.#lookup.set(this.#_getRowId(row), row);
            }
        }

        this.#_printRowsPerPage(containerElement);
    }

    #_printRowsPerPage(containerElement: HTMLDivElement): void {
        if (this.#config.paging == null) {
            return;
        }

        // Rows per page dropdown
        const container = document.createElement('div');
        const rowPerPageDropdown = document.createElement('select');
        rowPerPageDropdown.classList.add('row-per-page');
        const that = this;
        for (const num of this.#config.paging) {
            const option = document.createElement('option');
            if (num == -1) {
                option.innerText = 'Alle';
            } else {
                option.innerText = num.toString();
            }
            option.value = num.toString();
            if (num == this.#config.rowsPerPage) {
                option.selected = true;
            }

            rowPerPageDropdown.appendChild(option);
        }

        rowPerPageDropdown.onchange = function (ev) {
            const oldRowsPerPage = that.#config.rowsPerPage;
            that.#config.rowsPerPage = Number.parseInt((this as HTMLSelectElement).value);

            if (that.#config.rowsPerPage == -1) {
                that.#pageIndex = 0;
            } else {
                // Try to keep the current row visible.
                const firstVisibleIndex = oldRowsPerPage * that.#pageIndex;
                that.#pageIndex = Math.floor(firstVisibleIndex / that.#config.rowsPerPage);
            }

            that.newDraw();

            that.#_printPagination(true);
        }

        const frontLabel = document.createElement('label');
        frontLabel.dataset.i18n = 'num_runners_front_label';
        frontLabel.classList.add('row-per-page-label');
        frontLabel.innerText = 'Vis ';

        const backLabel = document.createElement('label');
        backLabel.dataset.i18n = 'num_runners_back_label';
        backLabel.classList.add('row-per-page-label');
        backLabel.innerText = ' løpere';

        container.append(frontLabel);
        container.append(rowPerPageDropdown);
        container.append(backLabel);
        containerElement.append(container);
    }

    #_printPagination(force: boolean): void {
        if (this.#config.paging == null) {
            return;
        }

        if (this.#pageIndexOnPaginationPrint == this.#pageIndex &&
            this.#numVisibleRowsOnPaginationPrint == this.#currentTable.rowsOnPage.length &&
            !force) {
            return;
        }
        this.#pageIndexOnPaginationPrint = this.#pageIndex;
        this.#numVisibleRowsOnPaginationPrint = this.#currentTable.rowsOnPage.length;

        this.#paginationContainer.innerHTML = '';

        const that = this;
        const prevPageButton = document.createElement('button');
        prevPageButton.classList.add('pagination');

        if (this.#pageIndex == 0) {
            prevPageButton.classList.add('disabled');
        }

        prevPageButton.onclick = function () {
            that.#pageIndex = Math.max(0, that.#pageIndex - 1);
            that.newDraw();
        };
        prevPageButton.innerText = '<<';
        this.#paginationContainer.appendChild(prevPageButton);

        const numPages = this.NumPages();
        const pagesToPrint: number[] = [];
        const PLACEHOLDER = -1;
        if (numPages <= 6) {
            for (let i = 0; i < numPages; ++i) {
                pagesToPrint.push(i);
            }
        } else {
            // Always include the first page
            pagesToPrint.push(0);

            if (this.#pageIndex < 4) {
                pagesToPrint.push(1, 2, 3, 4, PLACEHOLDER);
            } else if (this.#pageIndex > numPages - 5) {
                pagesToPrint.push(PLACEHOLDER, numPages - 5, numPages - 4, numPages - 3, numPages - 2);
            } else {
                pagesToPrint.push(PLACEHOLDER, this.#pageIndex - 1, this.#pageIndex, this.#pageIndex + 1, PLACEHOLDER);
            }

            // Always include the last page
            pagesToPrint.push(numPages - 1);
        }

        for (const idx of pagesToPrint) {
            const button = document.createElement('button');
            button.classList.add('pagination');

            if (idx == PLACEHOLDER) {
                button.innerText = '...';
                button.classList.add('disabled');
            } else {
                button.innerText = (idx + 1).toString();
                button.onclick = function () {
                    that.#pageIndex = idx;
                    that.newDraw();
                };
            }

            if (idx == this.#pageIndex) {
                button.classList.add('selected-page');
            }

            this.#paginationContainer.appendChild(button);
        }

        const nextPageButton = document.createElement('button');
        nextPageButton.classList.add('pagination');
        if (this.#pageIndex == numPages - 1) {
            nextPageButton.classList.add('disabled');
        }

        nextPageButton.onclick = function () {
            that.#pageIndex = Math.min(Math.ceil((that.#currentTable.allFilteredRows.length / that.#config.rowsPerPage) - 1), that.#pageIndex + 1);
            that.newDraw();
        }
        nextPageButton.innerText = '>>';
        this.#paginationContainer.appendChild(nextPageButton);

    }

    deleteRow(id: Key): void {
        this.deleteRows([id]);
    }

    data(): Value[] {
        return this.#data.slice();
    }

    pageLength(): number {
        return this.#config.rowsPerPage;
    }

    clear(): void {
        this.#data = [];
        this.#lookup.clear();
        this.newDraw();
        this.#_printPagination(true);
    }

    deleteRows(ids: Key[]): void {
        const lookup = new Set<Key>();
        for (const id of ids) {
            lookup.add(id);
        }

        const indexesToDelete: number[] = [];
        for (let i = 0; i < this.#data.length; ++i) {
            const id = this.#_getRowId(this.#data[i]);
            if (lookup.has(id)) {
                indexesToDelete.push(i);
                this.#lookup.delete(id);
            }
        }

        indexesToDelete.sort(function (a, b) { return a - b; });
        for (let i = indexesToDelete.length - 1; i >= 0; --i) {
            this.#data.splice(indexesToDelete[i], 1);
        }
    }

    forEach(func: (a: Value) => void, filter: ForEachFilter) {
        switch (filter) {
            case ForEachFilter.AllFiltered:
                for (const row of this.#currentTable.allFilteredRows) {
                    func(row);
                }
                break;
            case ForEachFilter.AllRows:
                for (const row of this.#data) {
                    func(row);
                }
                break;
            case ForEachFilter.OnlyVisible:
                for (const row of this.#currentTable.rowsOnPage) {
                    func(row.row);
                }
                break;
        }
    }

    #getSortFunc(): (a: Value, b: Value) => number {
        if (this.#sort.length == 0) {
            return null;
        }

        let sortCmps: ((a: Value, b: Value) => number)[] = [];

        // Guess the data type by looking at the first value.
        // Let us set up the sort function up front to avoid if/switch inside the sort function.
        for (const sort of this.#sort) {
            const column = this.#config.columns[sort.column_idx];
            const attrName = column.data;
            let render = column.render;
            if (render == null) {
                render = function (data: any, _row: Value, _type: RenderTypeEnum) {
                    return data;
                }
            }

            let type = 'string';
            if (column.sortType != null) {
                switch (column.sortType) {
                    case ColumnTypeEnum.Number:
                        type = 'number';
                        break;
                    case ColumnTypeEnum.String:
                        type = 'string';
                        break;
                }
            }
            else if (this.#data.length > 0) {
                type = typeof render(this.#data[0][attrName], this.#data[0], RenderTypeEnum.Sort, column);
            }

            switch (type) {
                case 'number':
                    switch (sort.sort_direction) {
                        case SortDirection.Ascending:
                            sortCmps.push(function (a, b) {
                                if (render(a[attrName], a, RenderTypeEnum.Sort, column) > render(b[attrName], b, RenderTypeEnum.Sort, column)) {
                                    return 1;
                                }

                                if (render(a[attrName], a, RenderTypeEnum.Sort, column) < render(b[attrName], b, RenderTypeEnum.Sort, column)) {
                                    return -1;
                                }

                                return 0;
                            });
                            break;
                        case SortDirection.Descending:
                            sortCmps.push(function (a, b) {
                                if (render(a[attrName], a, RenderTypeEnum.Sort, column) > render(b[attrName], b, RenderTypeEnum.Sort, column)) {
                                    return -1;
                                }

                                if (render(a[attrName], a, RenderTypeEnum.Sort, column) < render(b[attrName], b, RenderTypeEnum.Sort, column)) {
                                    return 1;
                                }

                                return 0;
                            }); break;
                    }
                    break;
                case 'string':
                    switch (sort.sort_direction) {
                        case SortDirection.Ascending:
                            sortCmps.push(function (a, b) {
                                return render(a[attrName], a, RenderTypeEnum.Sort, column).toString().localeCompare(render(b[attrName], b, RenderTypeEnum.Sort, column).toString());
                            });
                            break;
                        case SortDirection.Descending:
                            sortCmps.push(function (a, b) {
                                return render(b[attrName], b, RenderTypeEnum.Sort, column).toString().localeCompare(render(a[attrName], a, RenderTypeEnum.Sort, column).toString());
                            });
                            break;
                    }
                    break;
                default:
                    return null;
            }
        }
        return function (a, b) {
            for (const func of sortCmps) {
                const cmp = func(a, b);
                if (cmp != 0) {
                    return cmp;
                }
            }

            return 0;
        };
    }

    sort(columnIndex: number, shiftClicked: boolean): void {
        if (this.#config.columns[columnIndex].canSort !== null && this.#config.columns[columnIndex].canSort != null && !this.#config.columns[columnIndex].canSort) {
            return;
        }

        if (!shiftClicked) {
            if (this.#sort.length > 1) {
                this.#sort = [];
            }

            // Replace the current sort
            if (this.#sort.length == 0) {
                this.#sort.push({
                    'column_idx': columnIndex,
                    'sort_direction': SortDirection.Ascending
                });
            } else if (this.#sort[0].column_idx != columnIndex) {
                this.#sort[0].column_idx = columnIndex;
                this.#sort[0].sort_direction = SortDirection.Ascending;
            } else {
                this.#sort[0].sort_direction = this.#sort[0].sort_direction == SortDirection.Ascending ? SortDirection.Descending : SortDirection.Ascending;
            }
        } else {
            if (this.#sort.length == 0) {
                this.#sort.push({
                    'column_idx': columnIndex,
                    'sort_direction': SortDirection.Ascending
                });
            } else {
                // Do we have the column index?
                let found = false;
                for (let i = 0; i < this.#sort.length; ++i) {
                    if (this.#sort[i].column_idx == columnIndex) {
                        if (this.#sort[i].sort_direction == SortDirection.Ascending) {
                            this.#sort[i].sort_direction = SortDirection.Descending;
                        } else if (this.#sort.length > 1) {
                            this.#sort.splice(i, 1);
                        } else {
                            this.#sort[i].sort_direction = this.#sort[i].sort_direction == SortDirection.Ascending ? SortDirection.Descending : SortDirection.Ascending;
                        }
                        found = true;
                        break;
                    }
                }

                if (!found) {
                    this.#sort.push({
                        'column_idx': columnIndex,
                        'sort_direction': SortDirection.Ascending
                    });
                }

            }
        }

        this.#_MarkSortDirection();
        this.newDraw();
    }

    #_MarkSortDirection(): void {
        const table = this.#tbody.parentElement;
        if (table.children.length == 0) {
            return;
        }

        const thead = table.children[0];
        if (thead.children.length == 0) {
            return;
        }

        const theadRow = thead.children[0] as HTMLTableRowElement;
        for (let i = 0; i < theadRow.children.length; ++i) {
            const th = theadRow.children[i] as HTMLTableCellElement;
            th.classList.remove('up-arrow-active');
            th.classList.remove('down-arrow-active');

            // Find any sort that is applied to this column
            for (const sort of this.#sort) {
                if (sort.column_idx == i) {
                    switch (sort.sort_direction) {
                        case SortDirection.Ascending:
                            th.classList.add('up-arrow-active');
                            break;
                        case SortDirection.Descending:
                            th.classList.add('down-arrow-active');
                            break;
                    }
                    break;
                }
            }
        }
    }

    get(id: Key): Value {
        const found = this.#lookup.get(id);
        if (found === undefined) {
            return null;
        }
        return found;
    }

    upsertRow(row: Value): void {
        this.upsertRows([row]);
    }

    // NOTE: Elements will be removed from "rows"
    upsertRows(rows: Value[]): void {
        const toUpdate = new Map<Key, Value>();
        for (let i = rows.length - 1; i >= 0; --i) {
            const obj = rows[i];
            const id = this.#_getRowId(obj);
            if (this.#lookup.has(id)) {
                toUpdate.set(id, obj);
                rows.splice(i, 1);
            }
            this.#lookup.set(id, obj);
        }


        if (toUpdate.size > 0) {
            for (let i = 0; i < this.#data.length; ++i) {
                const id = this.#_getRowId(this.#data[i]);
                const found = toUpdate.get(id);
                if (found !== undefined) {
                    this.#data[i] = found;
                    toUpdate.delete(id);
                    if (toUpdate.size == 0) {
                        break;
                    }
                }
            }
        }

        for (const obj of rows) {
            this.#data.push(obj);
        }
    }


    setFilter(func: (a: Value) => boolean): void {
        this.#config.filter = func;
    }

    #_getRowId(row: Value): Key {
        return row[this.#config.id];
    }

    async export(filename: string): Promise<void> {
        const data: SheetData = [];
        let analyzer: { hasNonNumber: boolean, maxCharLength: number }[] = [];

        let headerRow = [];
        for (const column of this.#config.columns) {
            headerRow.push({
                value: column.title,
                fontWeight: 'bold'
            });

            analyzer.push({
                hasNonNumber: false,
                maxCharLength: column.title.length
            });
        }

        data.push(headerRow);


        var decoder = document.createElement('textarea');
        for (const row of this.#currentTable.allFilteredRows) {
            let excelRow = [];
            let colIdx = -1;
            for (const column of this.#config.columns) {
                colIdx++;
                let excelValue: string;
                if (column.render != null) {
                    excelValue = column.render(row[column.data], row, RenderTypeEnum.Display, column).toString();
                } else {
                    excelValue = row[column.data];
                }

                // Remove all HTML tags
                excelValue = excelValue.replace(/<[^>]*>/g, '');

                decoder.innerHTML = excelValue;
                excelValue = decoder.value;
                excelRow.push({
                    value: excelValue
                });


                if (excelValue != null) {
                    analyzer[colIdx].maxCharLength = Math.max(analyzer[colIdx].maxCharLength, excelValue.length);
                }

                if (isNaN(excelValue as any)) {
                    analyzer[colIdx].hasNonNumber = true;
                }
            }

            data.push(excelRow);
        }

        const columns: Columns = [];
        for (let i = 0; i < analyzer.length; ++i) {
            columns.push({
                width: analyzer[i].maxCharLength
            });

            if (analyzer[i].hasNonNumber) {
                continue;
            }

            let first = true;
            for (const row of data) {
                if (first) {
                    first = false;
                    continue;
                }
                row[i].type = Number;
                row[i].value = Number.parseFloat(row[i].value.toString());
                console.log(row[i]);
            }
        }



        await writeXlsxFile(data, {
            fileName: filename,
            columns: columns
        });
    }

    #_addEventListener = function (rowIdx: number) {
        const tableRow = this.#tbody.children[rowIdx] as HTMLTableRowElement;
        for (let j = 0; j < tableRow.children.length; ++j) {
            const that = this;
            (tableRow.children[j] as HTMLTableCellElement).onclick = function () {
                if (that.#config.cellClicked == null) {
                    return;
                }

                // Get column index
                let colIdx = -1;
                const tableCell = this as HTMLTableCellElement;
                for (const td of tableCell.parentElement.children) {
                    colIdx++;
                    if (td == tableCell) {
                        break;
                    }
                }

                const rowIdStr = tableCell.parentElement.dataset.id;
                let rowId: any;
                switch (typeof (that.#_getRowId(that.#data[0]))) {
                    case 'number':
                        rowId = Number(rowIdStr);
                        break;
                    default:
                        rowId = rowIdStr;
                        break;
                }

                that.#config.cellClicked(that.#config.columns[colIdx], that.#lookup.get(rowId));
            };
        }
    }

    newDraw(): void {
        const start = performance.now();

        const sortFunc = this.#getSortFunc();
        if (sortFunc != null) {
            this.#data.sort(sortFunc);
        }

        const newTable = this.createVirtualTable();

        const newIds = new Set<Key>();
        for (const row of newTable.rowsOnPage) {
            newIds.add(this.#_getRowId(row.row));
        }


        // Remove rows that should not exist anymore.
        const existingRowIds = new Set<Key>();
        for (let i = this.#currentTable.rowsOnPage.length - 1; i >= 0; --i) {
            const existingRow = this.#currentTable.rowsOnPage[i];
            if (!newIds.has(this.#_getRowId(existingRow.row))) {
                this.#currentTable.rowsOnPage.splice(i, 1);
                this.#tbody.children[i].remove();
            } else {
                existingRowIds.add(this.#_getRowId(existingRow.row));
            }
        }

        const that = this;
        const createTr = function (row: VirtualTableRowType<Value>) {
            let out = '<tr data-id="' + that.#_getRowId(row.row) + '"';

            const className = that.#_createClassName(row.cssStyle);
            if (className.length > 0) {
                out += ' class="' + className + '"';
            }
            out += '>';

            for (const cell of row.cells) {
                out += '<td'
                const className = that.#_createClassName(cell.cssStyle);
                if (className.length > 0) {
                    out += ' class="' + className + '"';
                }
                out += '>';
                out += cell.contents;
                out += '</td>';
            }

            out += '</tr>';
            return out;
        }

        const skipCompare = new Set<Key>();
        let toAppend = '';

        if (newTable.rowsOnPage.length == 0 && this.#config.columns != null && this.#placeholderRow == null && this.#config.i18n != null && this.#config.i18n.emptyTable != null) {
            // Add a placeholder row
            this.#placeholderRow = document.createElement('tr');
            this.#placeholderRow.innerHTML = '';

            const td = document.createElement('td');
            td.colSpan = this.#config.columns.length;
            td.innerText = this.#config.i18n.emptyTable;
            td.classList.add('table-placeholder');

            this.#placeholderRow.append(td);

            this.#tbody.append(this.#placeholderRow);
        } else if (newTable.rowsOnPage.length > 0 && this.#placeholderRow != null) {
            // Remove any placeholder rows
            this.#placeholderRow.remove();
            this.#placeholderRow = null;
        }

        for (let i = 0; i < newTable.rowsOnPage.length; ++i) {
            const row = newTable.rowsOnPage[i];
            if (!existingRowIds.has(this.#_getRowId(row.row))) {
                const appendRemaining = (i == this.#currentTable.rowsOnPage.length);
                this.#currentTable.rowsOnPage.splice(i, 0, row);

                // Should the remaining rows just be appended to the end?
                if (appendRemaining) {
                    toAppend += createTr(row);
                } else {
                    if (i == 0) {
                        this.#tbody.insertAdjacentHTML('afterbegin', createTr(row));
                    } else if (i == this.#currentTable.rowsOnPage.length - 1) {
                        this.#tbody.insertAdjacentHTML('beforeend', createTr(row));
                    } else {
                        this.#tbody.children[i].insertAdjacentHTML('beforebegin', createTr(row));
                    }
                    this.#_addEventListener(i);
                }

                skipCompare.add(this.#_getRowId(row.row));
            }
        }


        if (toAppend.length > 0) {
            let lastIdx = this.#tbody.children.length;
            this.#tbody.insertAdjacentHTML('beforeend', toAppend);

            for (let i = lastIdx; i < this.#tbody.children.length; ++i) {
                this.#_addEventListener(i);
            }
        }

        var swapElements = function (el1: ChildNode, el2: ChildNode) {
            var p2 = el2.parentNode, n2 = el2.nextSibling
            if (n2 === el1) return p2.insertBefore(el1, el2)
            el1.parentNode.insertBefore(el2, el1);
            p2.insertBefore(el1, n2);
        }

        // Move rows that are in the wrong order
        for (let i = 0; i < newTable.rowsOnPage.length; ++i) {
            const expectedId = this.#_getRowId(newTable.rowsOnPage[i].row);
            if (expectedId != this.#_getRowId(this.#currentTable.rowsOnPage[i].row)) {
                // "this.#currentTable" has the wrong row in this position.
                // "i" is the correct position it should be in.
                for (let j = i + 1; j < this.#currentTable.rowsOnPage.length; ++j) {
                    if (this.#_getRowId(this.#currentTable.rowsOnPage[j].row) == expectedId) {
                        // "j" is now the current position of the row.
                        // Change the DOM first.
                        swapElements(this.#tbody.childNodes[i], this.#tbody.childNodes[j]);

                        // Then swap the array elements
                        const tmp = this.#currentTable.rowsOnPage[i];
                        this.#currentTable.rowsOnPage[i] = this.#currentTable.rowsOnPage[j];
                        this.#currentTable.rowsOnPage[j] = tmp;
                        break;
                    }
                }
            }
        }

        // Compare cell of each row
        for (let i = 0; i < this.#currentTable.rowsOnPage.length; ++i) {
            if (skipCompare.has(this.#_getRowId(this.#currentTable.rowsOnPage[i].row))) {
                continue;
            }

            for (let j = 0; j < this.#currentTable.rowsOnPage[i].cells.length; ++j) {
                if (this.#currentTable.rowsOnPage[i].cells[j].contents != newTable.rowsOnPage[i].cells[j].contents) {
                    this.#currentTable.rowsOnPage[i].cells[j].contents = newTable.rowsOnPage[i].cells[j].contents;
                    this.#tbody.children[i].children[j].innerHTML = newTable.rowsOnPage[i].cells[j].contents;
                }
            }

            const currentRowStyle = this.#_createClassName(this.#currentTable.rowsOnPage[i].cssStyle);
            const expectedRowStyle = this.#_createClassName(newTable.rowsOnPage[i].cssStyle);
            if (expectedRowStyle != currentRowStyle) {
                const tableRow = this.#tbody.children[i] as HTMLTableRowElement;
                tableRow.className = expectedRowStyle;
            }

            for (let j = 0; j < this.#currentTable.rowsOnPage[i].cells.length; ++j) {
                const currentCellStyle = this.#_createClassName(this.#currentTable.rowsOnPage[i].cells[j].cssStyle);
                const expectedCellStyle = this.#_createClassName(newTable.rowsOnPage[i].cells[j].cssStyle);

                if (currentCellStyle != expectedCellStyle) {
                    const tableCell = this.#tbody.children[i].children[j] as HTMLTableCellElement;
                    tableCell.className = expectedCellStyle;
                }
            }
        }

        this.#currentTable = newTable;
        this.#_printPagination(false);
    }

    #config: ConfigType<Value>;
    #lookup = new Map<Key, Value>();

    #data: Value[] = [];
    #numVisibleRowsOnPaginationPrint = 0;

    #pageIndex = 0;
    #pageIndexOnPaginationPrint = -1;
    #currentTable: VirtualTableType<Value> = { 'rowsOnPage': [], 'allFilteredRows': [] };

    #sort: TableSortType[] = [];

    // Page elements
    #tbody = document.createElement('tbody');
    #paginationContainer = document.createElement('div');

    #placeholderRow: HTMLTableRowElement = null;
}
